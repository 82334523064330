<template>
  <a-modal
    title="权限分配"
    v-model:visible="visible"
    :get-container="modalContainer"
    :after-close="afterClose"
    :mask-closable="false"
  >
    <template #footer>
      <a-button @click="visible = false">取消</a-button>
      <a-button type="primary" @click="doSubmit">确定</a-button>
    </template>
    <div style="max-height: 400px; overflow: auto">
      <a-tree
        ref="tree"
        checkable
        :selectable="false"
        v-model:checked-keys="checkedKeys.list"
        :tree-data="menus"
        @check="nodeChecked"
      ></a-tree>
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { listMenus, updateMenus } from "@/api/sys/sysRoleApi";
import { useStore } from "vuex";
import { wrapMenuTree } from "@/utils/routerUtils";

export default {
  name: "Auth",
  emits: ["close"],
  props: ["id", "modalContainer"],
  setup(props, { emit }) {
    const visible = ref(true);
    const disabled = ref(false);
    const store = useStore();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const afterClose = () => {
      emit("close");
    };
    const rawMenus = store.state.sidebar.rawMenus;
    const menus = reactive(wrapMenuTree(rawMenus));
    // 菜单keys,可以理解为半选状态的
    let menuTypeKeys = [];
    // 初始化时只取按钮数据，最后与menuTypeKeys合并，需要去重
    const checkedKeys = reactive({ list: [] });
    const doSubmit = async () => {
      let keys = [...checkedKeys.list, ...menuTypeKeys];
      let r = await showConfirm("修改权限将会对已登录的用户产生影响,是否继续?");
      if (r) {
        disabled.value = true;
        await showLoading("正在修改，请稍后...");
        try {
          await updateMenus(props.id, keys);
          await closeLoading();
          await showTipMessage("修改成功");
          disabled.value = false;
        } catch (e) {
          await closeLoading();
          await showErr(e);
          disabled.value = false;
        }
      }
    };
    onMounted(async () => {
      disabled.value = true;
      try {
        let { data } = await listMenus(props.id);
        checkedKeys.list = data
          .filter(item => item.type === 2)
          .map(item => item.id);
        menuTypeKeys = data
          .filter(item => item.type === 1)
          .map(item => item.id);
      } catch (e) {
        await showErr(e);
      }
    });
    const nodeChecked = (checked, { halfCheckedKeys, node }) => {
      // console.log(node.title, node.checked, node.eventKey, node.isLeaf2())
      if (node.isLeaf2() && !node.checked) {
        // 是叶子节点且还是选中的状态，这里打印奇怪，选中:node.checked=false,
        // 查找查看权限有没有
        let id = node.eventKey; // 这里对应的是key,初始化时将ID赋给了key
        let viewKey = id.substr(0, id.length - 1) + "0";
        if (checked.indexOf(viewKey) === -1) {
          checked.push(viewKey);
        }
      }
      menuTypeKeys = halfCheckedKeys;
      checkedKeys.list = [...checked];
    };
    return {
      ...toRefs(props),
      visible,
      afterClose,
      doSubmit,
      menus,
      checkedKeys,
      nodeChecked,
    };
  },
};
</script>

<style scoped></style>
