import axios from "axios";

export const allRoles = () => {
  return axios.get("/sys/role/all");
};

export const list = (params, page, pageSize) => {
  return axios.get("/sys/role/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/sys/role/save", vo);
};

export const detail = id => {
  return axios.get(`/sys/role/${id}/detail`);
};

export const update = (id, vo) => {
  return axios.put(`/sys/role/${id}/update`, vo);
};

export const deleteRole = id => {
  return axios.delete(`/sys/role/${id}/delete`);
};

export const listMenus = id => {
  return axios.get(`/sys/role/${id}/menus`);
};

export const updateMenus = (id, list) => {
  return axios.put(`/sys/role/${id}/menus`, list);
};
